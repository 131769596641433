/* Modernizr (Custom Build) | MIT & BSD
 * Build: http://modernizr.com/download/#-csstransforms3d-csstransitions-touch-shiv-load-cssclasses-prefixed-teststyles-testprops-testallprops-prefixes-domprefixes-mq-respond
 */
;
window.Modernizr = function (e, t, n) {
    function C(e) {
        f.cssText = e
    }

    function k(e, t) {
        return C(h.join(e + ";") + (t || ""))
    }

    function L(e, t) {
        return typeof e === t
    }

    function A(e, t) {
        return !!~("" + e).indexOf(t)
    }

    function O(e, t) {
        for (var r in e) {
            var i = e[r];
            if (!A(i, "-") && f[i] !== n) return t == "pfx" ? i : !0
        }
        return !1
    }

    function M(e, t, r) {
        for (var i in e) {
            var s = t[e[i]];
            if (s !== n) return r === !1 ? e[i] : L(s, "function") ? s.bind(r || t) : s
        }
        return !1
    }

    function _(e, t, n) {
        var r = e.charAt(0).toUpperCase() + e.slice(1),
            i = (e + " " + d.join(r + " ") + r).split(" ");
        return L(t, "string") || L(t, "undefined") ? O(i, t) : (i = (e + " " + v.join(r + " ") + r).split(" "), M(i, t, n))
    }
    var r = "2.8.3",
        i = {},
        s = !0,
        o = t.documentElement,
        u = "modernizr",
        a = t.createElement(u),
        f = a.style,
        l, c = {}.toString,
        h = " -webkit- -moz- -o- -ms- ".split(" "),
        p = "Webkit Moz O ms",
        d = p.split(" "),
        v = p.toLowerCase().split(" "),
        m = {},
        g = {},
        y = {},
        b = [],
        w = b.slice,
        E, S = function (e, n, r, i) {
            var s, a, f, l, c = t.createElement("div"),
                h = t.body,
                p = h || t.createElement("body");
            if (parseInt(r, 10))
                while (r--) f = t.createElement("div"), f.id = i ? i[r] : u + (r + 1), c.appendChild(f);
            return s = ["&#173;", '<style id="s', u, '">', e, "</style>"].join(""), c.id = u, (h ? c : p).innerHTML += s, p.appendChild(c), h || (p.style.background = "", p.style.overflow = "hidden", l = o.style.overflow, o.style.overflow = "hidden", o.appendChild(p)), a = n(c, e), h ? c.parentNode.removeChild(c) : (p.parentNode.removeChild(p), o.style.overflow = l), !!a
        },
        x = function (t) {
            var n = e.matchMedia || e.msMatchMedia;
            if (n) return n(t) && n(t).matches || !1;
            var r;
            return S("@media " + t + " { #" + u + " { position: absolute; } }", function (t) {
                r = (e.getComputedStyle ? getComputedStyle(t, null) : t.currentStyle)["position"] == "absolute"
            }), r
        },
        T = {}.hasOwnProperty,
        N;
    !L(T, "undefined") && !L(T.call, "undefined") ? N = function (e, t) {
        return T.call(e, t)
    } : N = function (e, t) {
        return t in e && L(e.constructor.prototype[t], "undefined")
    }, Function.prototype.bind || (Function.prototype.bind = function (t) {
        var n = this;
        if (typeof n != "function") throw new TypeError;
        var r = w.call(arguments, 1),
            i = function () {
                if (this instanceof i) {
                    var e = function () { };
                    e.prototype = n.prototype;
                    var s = new e,
                        o = n.apply(s, r.concat(w.call(arguments)));
                    return Object(o) === o ? o : s
                }
                return n.apply(t, r.concat(w.call(arguments)))
            };
        return i
    }), m.touch = function () {
        var n;
        return "ontouchstart" in e || e.DocumentTouch && t instanceof DocumentTouch ? n = !0 : S(["@media (", h.join("touch-enabled),("), u, ")", "{#modernizr{top:9px;position:absolute}}"].join(""), function (e) {
            n = e.offsetTop === 9
        }), n
    }, m.csstransforms3d = function () {
        var e = !!_("perspective");
        return e && "webkitPerspective" in o.style && S("@media (transform-3d),(-webkit-transform-3d){#modernizr{left:9px;position:absolute;height:3px;}}", function (t, n) {
            e = t.offsetLeft === 9 && t.offsetHeight === 3
        }), e
    }, m.csstransitions = function () {
        return _("transition")
    };
    for (var D in m) N(m, D) && (E = D.toLowerCase(), i[E] = m[D](), b.push((i[E] ? "" : "no-") + E));
    return i.addTest = function (e, t) {
        if (typeof e == "object")
            for (var r in e) N(e, r) && i.addTest(r, e[r]);
        else {
            e = e.toLowerCase();
            if (i[e] !== n) return i;
            t = typeof t == "function" ? t() : t, typeof s != "undefined" && s && (o.className += " " + (t ? "" : "no-") + e), i[e] = t
        }
        return i
    }, C(""), a = l = null,
        function (e, t) {
            function c(e, t) {
                var n = e.createElement("p"),
                    r = e.getElementsByTagName("head")[0] || e.documentElement;
                return n.innerHTML = "x<style>" + t + "</style>", r.insertBefore(n.lastChild, r.firstChild)
            }

            function h() {
                var e = y.elements;
                return typeof e == "string" ? e.split(" ") : e
            }

            function p(e) {
                var t = f[e[u]];
                return t || (t = {}, a++, e[u] = a, f[a] = t), t
            }

            function d(e, n, r) {
                n || (n = t);
                if (l) return n.createElement(e);
                r || (r = p(n));
                var o;
                return r.cache[e] ? o = r.cache[e].cloneNode() : s.test(e) ? o = (r.cache[e] = r.createElem(e)).cloneNode() : o = r.createElem(e), o.canHaveChildren && !i.test(e) && !o.tagUrn ? r.frag.appendChild(o) : o
            }

            function v(e, n) {
                e || (e = t);
                if (l) return e.createDocumentFragment();
                n = n || p(e);
                var r = n.frag.cloneNode(),
                    i = 0,
                    s = h(),
                    o = s.length;
                for (; i < o; i++) r.createElement(s[i]);
                return r
            }

            function m(e, t) {
                t.cache || (t.cache = {}, t.createElem = e.createElement, t.createFrag = e.createDocumentFragment, t.frag = t.createFrag()), e.createElement = function (n) {
                    return y.shivMethods ? d(n, e, t) : t.createElem(n)
                }, e.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + h().join().replace(/[\w\-]+/g, function (e) {
                    return t.createElem(e), t.frag.createElement(e), 'c("' + e + '")'
                }) + ");return n}")(y, t.frag)
            }

            function g(e) {
                e || (e = t);
                var n = p(e);
                return y.shivCSS && !o && !n.hasCSS && (n.hasCSS = !!c(e, "article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}")), l || m(e, n), e
            }
            var n = "3.7.0",
                r = e.html5 || {},
                i = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
                s = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
                o, u = "_html5shiv",
                a = 0,
                f = {},
                l;
            (function () {
                try {
                    var e = t.createElement("a");
                    e.innerHTML = "<xyz></xyz>", o = "hidden" in e, l = e.childNodes.length == 1 || function () {
                        t.createElement("a");
                        var e = t.createDocumentFragment();
                        return typeof e.cloneNode == "undefined" || typeof e.createDocumentFragment == "undefined" || typeof e.createElement == "undefined"
                    }()
                } catch (n) {
                    o = !0, l = !0
                }
            })();
            var y = {
                elements: r.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output progress section summary template time video",
                version: n,
                shivCSS: r.shivCSS !== !1,
                supportsUnknownElements: l,
                shivMethods: r.shivMethods !== !1,
                type: "default",
                shivDocument: g,
                createElement: d,
                createDocumentFragment: v
            };
            e.html5 = y, g(t)
        }(this, t), i._version = r, i._prefixes = h, i._domPrefixes = v, i._cssomPrefixes = d, i.mq = x, i.testProp = function (e) {
            return O([e])
        }, i.testAllProps = _, i.testStyles = S, i.prefixed = function (e, t, n) {
            return t ? _(e, t, n) : _(e, "pfx")
        }, o.className = o.className.replace(/(^|\s)no-js(\s|$)/, "$1$2") + (s ? " js " + b.join(" ") : ""), i
}(this, this.document),
    function (e, t, n) {
        function r(e) {
            return "[object Function]" == d.call(e)
        }

        function i(e) {
            return "string" == typeof e
        }

        function s() { }

        function o(e) {
            return !e || "loaded" == e || "complete" == e || "uninitialized" == e
        }

        function u() {
            var e = v.shift();
            m = 1, e ? e.t ? h(function () {
                ("c" == e.t ? k.injectCss : k.injectJs)(e.s, 0, e.a, e.x, e.e, 1)
            }, 0) : (e(), u()) : m = 0
        }

        function a(e, n, r, i, s, a, f) {
            function l(t) {
                if (!d && o(c.readyState) && (w.r = d = 1, !m && u(), c.onload = c.onreadystatechange = null, t)) {
                    "img" != e && h(function () {
                        b.removeChild(c)
                    }, 50);
                    for (var r in T[n]) T[n].hasOwnProperty(r) && T[n][r].onload()
                }
            }
            var f = f || k.errorTimeout,
                c = t.createElement(e),
                d = 0,
                g = 0,
                w = {
                    t: r,
                    s: n,
                    e: s,
                    a: a,
                    x: f
                };
            1 === T[n] && (g = 1, T[n] = []), "object" == e ? c.data = n : (c.src = n, c.type = e), c.width = c.height = "0", c.onerror = c.onload = c.onreadystatechange = function () {
                l.call(this, g)
            }, v.splice(i, 0, w), "img" != e && (g || 2 === T[n] ? (b.insertBefore(c, y ? null : p), h(l, f)) : T[n].push(c))
        }

        function f(e, t, n, r, s) {
            return m = 0, t = t || "j", i(e) ? a("c" == t ? E : w, e, t, this.i++, n, r, s) : (v.splice(this.i++, 0, e), 1 == v.length && u()), this
        }

        function l() {
            var e = k;
            return e.loader = {
                load: f,
                i: 0
            }, e
        }
        var c = t.documentElement,
            h = e.setTimeout,
            p = t.getElementsByTagName("script")[0],
            d = {}.toString,
            v = [],
            m = 0,
            g = "MozAppearance" in c.style,
            y = g && !!t.createRange().compareNode,
            b = y ? c : p.parentNode,
            c = e.opera && "[object Opera]" == d.call(e.opera),
            c = !!t.attachEvent && !c,
            w = g ? "object" : c ? "script" : "img",
            E = c ? "script" : w,
            S = Array.isArray || function (e) {
                return "[object Array]" == d.call(e)
            },
            x = [],
            T = {},
            N = {
                timeout: function (e, t) {
                    return t.length && (e.timeout = t[0]), e
                }
            },
            C, k;
        k = function (e) {
            function t(e) {
                var e = e.split("!"),
                    t = x.length,
                    n = e.pop(),
                    r = e.length,
                    n = {
                        url: n,
                        origUrl: n,
                        prefixes: e
                    },
                    i, s, o;
                for (s = 0; s < r; s++) o = e[s].split("="), (i = N[o.shift()]) && (n = i(n, o));
                for (s = 0; s < t; s++) n = x[s](n);
                return n
            }

            function o(e, i, s, o, u) {
                var a = t(e),
                    f = a.autoCallback;
                a.url.split(".").pop().split("?").shift(), a.bypass || (i && (i = r(i) ? i : i[e] || i[o] || i[e.split("/").pop().split("?")[0]]), a.instead ? a.instead(e, i, s, o, u) : (T[a.url] ? a.noexec = !0 : T[a.url] = 1, s.load(a.url, a.forceCSS || !a.forceJS && "css" == a.url.split(".").pop().split("?").shift() ? "c" : n, a.noexec, a.attrs, a.timeout), (r(i) || r(f)) && s.load(function () {
                    l(), i && i(a.origUrl, u, o), f && f(a.origUrl, u, o), T[a.url] = 2
                })))
            }

            function u(e, t) {
                function n(e, n) {
                    if (e) {
                        if (i(e)) n || (f = function () {
                            var e = [].slice.call(arguments);
                            l.apply(this, e), c()
                        }), o(e, f, t, 0, u);
                        else if (Object(e) === e)
                            for (p in h = function () {
                                    var t = 0,
                                        n;
                                    for (n in e) e.hasOwnProperty(n) && t++;
                                    return t
                            }(), e) e.hasOwnProperty(p) && (!n && !--h && (r(f) ? f = function () {
                                var e = [].slice.call(arguments);
                                l.apply(this, e), c()
                            } : f[p] = function (e) {
                                return function () {
                                    var t = [].slice.call(arguments);
                                    e && e.apply(this, t), c()
                                }
                            }(l[p])), o(e[p], f, t, p, u))
                    } else !n && c()
                }
                var u = !!e.test,
                    a = e.load || e.both,
                    f = e.callback || s,
                    l = f,
                    c = e.complete || s,
                    h, p;
                n(u ? e.yep : e.nope, !!a), a && n(a)
            }
            var a, f, c = this.yepnope.loader;
            if (i(e)) o(e, 0, c, 0);
            else if (S(e))
                for (a = 0; a < e.length; a++) f = e[a], i(f) ? o(f, 0, c, 0) : S(f) ? k(f) : Object(f) === f && u(f, c);
            else Object(e) === e && u(e, c)
        }, k.addPrefix = function (e, t) {
            N[e] = t
        }, k.addFilter = function (e) {
            x.push(e)
        }, k.errorTimeout = 1e4, null == t.readyState && t.addEventListener && (t.readyState = "loading", t.addEventListener("DOMContentLoaded", C = function () {
            t.removeEventListener("DOMContentLoaded", C, 0), t.readyState = "complete"
        }, 0)), e.yepnope = l(), e.yepnope.executeStack = u, e.yepnope.injectJs = function (e, n, r, i, a, f) {
            var l = t.createElement("script"),
                c, d, i = i || k.errorTimeout;
            l.src = e;
            for (d in r) l.setAttribute(d, r[d]);
            n = f ? u : n || s, l.onreadystatechange = l.onload = function () {
                !c && o(l.readyState) && (c = 1, n(), l.onload = l.onreadystatechange = null)
            }, h(function () {
                c || (c = 1, n(1))
            }, i), a ? l.onload() : p.parentNode.insertBefore(l, p)
        }, e.yepnope.injectCss = function (e, n, r, i, o, a) {
            var i = t.createElement("link"),
                f, n = a ? u : n || s;
            i.href = e, i.rel = "stylesheet", i.type = "text/css";
            for (f in r) i.setAttribute(f, r[f]);
            o || (p.parentNode.insertBefore(i, p), h(n, 0))
        }
    }(this, document), Modernizr.load = function () {
        yepnope.apply(window, [].slice.call(arguments, 0))
    };